import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout, Pager, LinkButton, SEO } from "../components";
import { articleDateFormat } from "../misc/utils";

const ArticleListTemplate = ({ data, pageContext, location }) => {
  const articles = data.allStrapiArticle.edges;
  const trimDescription = (description) => {
    if (description.length > 160) {
      return description.substring(0, 157) + "...";
    }
    return description;
  };
  return (
    <Layout>
      <SEO
        title="Blog de energía limpia"
        description="Sigue el blog de Bright para conocer las características de los nuevos servicios, noticias, consejos y respuestas a tus dudas sobre energías limpias."
      />
      {/* Hero Start */}
      <div className="pt-24 bg-white">
        <div className="mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="text-center">
            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl">
              Blog
            </h1>
            {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Nulla vehicula tortor eu est vulputate tempus.
            </p> */}
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* Archive Start */}
      <div className="flex mx-auto bg-white pt-16 pb-8 lg:pt-16 lg:pb-16 px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="mt-12 max-w-lg mx-auto grid gap-16 lg:grid-cols-3 lg:max-w-none">
          {articles.map((article) => (
            <Link to={`/blog/${article.node.slug}`}>
              <div
                key={article.node.title}
                className="flex flex-col h-full rounded-lg border border-gray-200 overflow-hidden shadow-md transform transition duration-200 hover:shadow-lg hover:scale-101"
              >
                <div className="flex-shrink-0">
                  <GatsbyImage
                    alt={article.title}
                    className="h-48 w-full object-cover"
                    image={getImage(article.node.image.localFile)}
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-blue-600">
                      <div className="text-gray-500 font-semibold">
                        {article.node.category.name}
                      </div>
                    </p>
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {article.node.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {trimDescription(article.node.description)}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center">
                    {article.node.author.picture && (
                      <div className="flex-shrink-0">
                        <GatsbyImage
                          alt={article.node.author.name}
                          className="h-10 w-10 rounded-full"
                          image={getImage(
                            article.node.author.picture.localFile,
                          )}
                        />
                      </div>
                    )}
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        <div>{article.node.author.name}</div>
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        {articleDateFormat(article.node.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* Archive End */}
      {/* Pager Start */}
      <div className="flex mx-auto bg-white mb-16 md:mb-24 lg:px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <Pager pageContext={pageContext} />
      </div>
      {/* Pager Start */}

      {/* Contact Us Start */}
      <div className="bg-gray-50">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500">
            ¿Tienes comentarios o preguntas?
          </h2>
          <p className="mt-6 text-lg leading-6 text-gray-600">
            Nos encantaría escucharte
          </p>
          <div className="max-w-xs mx-auto mt-6">
            <LinkButton shadow arrow size="large" to="/contacto">
              Contáctanos
            </LinkButton>
          </div>
        </div>
      </div>
      {/* Contact Us End */}
    </Layout>
  );
};

export default ArticleListTemplate;

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiArticle(
      skip: $skip
      limit: $limit
      sort: { fields: [created_at], order: DESC }
    ) {
      edges {
        node {
          id
          created_at(formatString: "LL")
          title
          slug
          description
          content
          category {
            name
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 500)
              }
            }
          }
          author {
            id
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 112)
                }
              }
            }
            role
          }
        }
      }
    }
  }
`;
